@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-input {
    @apply w-full p-2 border rounded-md;
  }

  /* Map styles */
  .country-path {
    @apply fill-white stroke-black stroke-[0.3] cursor-pointer transition-colors duration-200;
    vector-effect: non-scaling-stroke;
  }

  .country-path:hover {
    @apply fill-blue-200;
  }

  .country-path.selected {
    @apply fill-blue-500;
  }
  
  /* Add any other custom classes */
}